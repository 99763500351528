import {empty} from '@/utils/variables'
import {PhoneNumberUtil, PhoneNumberFormat as PNF} from 'google-libphonenumber'

export default {
  methods: {
    call: function (number) {
      window.location = 'tel:' + number
    },
    sendmail: function (address) {
      window.location = 'mailto:' + address
    },
    findPhone: function (user) {
      if(empty(user.phone) && empty(user.mobile)) {
        return null
      } else {
        return empty(user.mobile) ? this.formatPhoneNumber(user.phone) : this.formatPhoneNumber(user.mobile)
      }
    },
    formatPhoneNumber: function(number) {
      if(number) {
        return PhoneNumberUtil.getInstance().format(
          PhoneNumberUtil.getInstance().parseAndKeepRawInput(number)
          , PNF.INTERNATIONAL)
      } else {
        return 'Non renseigné'
      }
    }
  }
}
